import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import {Alert, AlertTitle} from '@mui/material'
import {
    ServerConfigRouter,
    ServerConfigRouterWrapper
} from "../../../../interfaces/ServerConfigType";
import GeneralServerConfigFC from "./general";
import {ApiError} from "../../../../interfaces/ErrorType";
import {updateServerConfig} from "../../../../actions/server";
import AccessControlServerConfigFC from "./access/control";
import ErrorNotificationServerConfigFC from "./error/notification";
import TranslationProviderServerConfigFC from "./translation/provider";
import MongoServerConfigFC from "./mongo";
import ContentServerConfigFC from "./content";

const ServerConfigRouterFC: FunctionComponent<ServerConfigRouterWrapper> = ({
                                                            isNew = false,
                                                            wrapper,
                                                        }) => {
    const [serverConfig, setServerConfig] = useState<ServerConfigRouter<any>>(wrapper!!)
    const validConfigKeys = ['GENERAL', 'MONGO', 'ERROR_NOTIFICATION', 'ACCESS_CONTROL', 'TRANSLATION_STRATEGY', 'CONTENT'];

    /**
     * Mutate server config update
     */
    const serverConfigUpdateMutation = useMutation<any, ApiError, any>(
        updateServerConfig,
        {
            onSuccess: (data) => {
                console.log(data)
                setServerConfig({
                    ...serverConfig,
                    value: data
                })
            },
        }
    )

    /**
     * Page containing locale details
     */
    return (
        <>
            {validConfigKeys.includes(serverConfig.key) ? (
                <>
                    {serverConfig.key === 'GENERAL' && (
                        <GeneralServerConfigFC
                            key={serverConfig.key}
                            value={serverConfig.value}
                            onChange={(e: any) => serverConfigUpdateMutation.mutate({...serverConfig, value: e})}
                            isLoading={serverConfigUpdateMutation.isLoading}
                            setValue={(e: any) => setServerConfig({...serverConfig, value: e})}
                        />
                    )}
                    {serverConfig.key === 'MONGO' && (
                        <MongoServerConfigFC
                            key={serverConfig.key}
                            value={serverConfig.value}
                            onChange={(e: any) => serverConfigUpdateMutation.mutate({...serverConfig, value: e})}
                            isLoading={serverConfigUpdateMutation.isLoading}
                            setValue={(e: any) => setServerConfig({...serverConfig, value: e})}
                        />
                    )}
                    {serverConfig.key === 'ERROR_NOTIFICATION' && (
                        <ErrorNotificationServerConfigFC
                            key={serverConfig.key}
                            value={serverConfig.value}
                            onChange={(e: any) => serverConfigUpdateMutation.mutate({...serverConfig, value: e})}
                            isLoading={serverConfigUpdateMutation.isLoading}
                            setValue={(e: any) => setServerConfig({...serverConfig, value: e})}
                        />
                    )}
                    {serverConfig.key === 'ACCESS_CONTROL' && (
                        <AccessControlServerConfigFC
                            key={serverConfig.key}
                            value={serverConfig.value}
                            onChange={(e: any) => serverConfigUpdateMutation.mutate({...serverConfig, value: e})}
                            isLoading={serverConfigUpdateMutation.isLoading}
                            setValue={(e: any) => setServerConfig({...serverConfig, value: e})}
                        />
                    )}
                    {serverConfig.key === 'TRANSLATION_STRATEGY' && (
                        <TranslationProviderServerConfigFC
                            key={serverConfig.key}
                            value={serverConfig.value}
                            onChange={(e: any) => serverConfigUpdateMutation.mutate({...serverConfig, value: e})}
                            isLoading={serverConfigUpdateMutation.isLoading}
                            setValue={(e: any) => setServerConfig({...serverConfig, value: e})}
                        />
                    )}
                    {serverConfig.key === 'CONTENT' && (
                        <ContentServerConfigFC
                            key={serverConfig.key}
                            value={serverConfig.value}
                            onChange={(e: any) => serverConfigUpdateMutation.mutate({...serverConfig, value: e})}
                            isLoading={serverConfigUpdateMutation.isLoading}
                            setValue={(e: any) => setServerConfig({...serverConfig, value: e})}
                        />
                    )}
                </>
            ) : (
                <Alert severity="error">
                    <AlertTitle>Configuration Error:</AlertTitle>
                    {`Unrecognized configuration type: ${serverConfig.key}`}
                </Alert>
            )}
        </>
    );
}

export default ServerConfigRouterFC
