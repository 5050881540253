import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Alert, Box, Grid, ThemeProvider,
    Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { v4 as uuidv4 } from 'uuid'
import React, { FunctionComponent, useMemo } from 'react'
import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    components: {
        MuiAlert: {
            styleOverrides: {
                root: {
                    paddingTop: '0 !important',
                    paddingBottom: '0 !important',
                },
            },
        },
    },
});

const AccordionExt: FunctionComponent<AccordionProp> = ({
    name,
    title,
    error,
    errorMessage,
    component,
    children,
    actionRenderer
}) => {
    const uuid = useMemo(() => uuidv4(), [])

    return (
        <Accordion id={uuid}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {error && errorMessage && (
                    <Grid container justifyContent="flex-start">
                        <ThemeProvider theme={theme}>
                            <Alert severity="error">
                                {errorMessage}
                            </Alert>
                        </ThemeProvider>
                    </Grid>
                )}
                {!error && <Typography width='100%'>{title}</Typography>}
                {actionRenderer && (
                    <Grid marginRight="2em" container justifyContent="flex-end">
                        {actionRenderer}
                    </Grid>
                )}
            </AccordionSummary>
            <AccordionDetails>{component}</AccordionDetails>
        </Accordion>
    )
}

interface AccordionProp {
    name: string
    title: string
    error?: boolean | undefined
    errorMessage?: string | undefined
    component: React.ReactElement
    children?: React.ReactElement[] | undefined
    actionRenderer?: React.ReactElement | undefined
}

export default AccordionExt
