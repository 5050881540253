import React, { useState } from 'react'
import { connect } from 'react-redux'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../../components/DataGridFilter'
import {
    fetchContentRecommendations,
    fetchRelationships,
} from '../../../../actions/contentAssists'
import { useAuthQueryWithQueryFunction } from '../../../../extensions/UseAuthQuery'
import { Box, TextField } from '@mui/material'
import Header from '../../../../components/Header'
import ContentAssistDetail from '../detail'
import AutocompleteExt from '../../../../components/Autocomplete'
import {ContentAssist} from '../../../../interfaces/ContentAssistType'
import { ApiError } from '../../../../interfaces/ErrorType'
import {KeyPairs, TenantSetting} from "../../../../interfaces/SettingType";
import {
    ContentAssistRecommendationFilterCustomFilterOptions
} from "../../../../interfaces/ContentAsssitRecommendationType";
import {
    calculateWeekRangeByMonthAndSelectedDevelopmentStage,
    fetchDevelopmentStages
} from "../../../../actions/developmentStage";
import CheckboxExt from "../../../../components/Checkbox";
import {useMutation} from "react-query";
import LoadingOverlay from 'react-loading-overlay-ts';

/**
 * Render Content Assist Recommendations page
 *
 * @returns contents
 */
function ContentAssistRecommendations(props: { localeList: any, userSetting: TenantSetting | undefined }) {
    const [change, setChange] = useState<string>()
    const translationList = props.localeList
    const contentLocalizations = props.userSetting?.contentAssistMapping?.localizations
    const [customSearchOptions, setCustomSearchOptions] =
        useState<ContentAssistRecommendationFilterCustomFilterOptions>({
            queryVector: '',
            topk: 10,
            weekFrom: NaN,
            weekTo: NaN,
            developmentStageIds: [],
            includeScore: true,
            relationshipId: '',
            acceptableVectorSearchScore: 0.8,
            contentLocalizationCodes: [],
            structureRegex: '',
            milestone: false,
            translateTo: '',
        })

    const translationOptions = translationList.map((locale: any) => {
        return {
            label: locale.name,
            value: locale.code,
        }
    })

    const contentLocalizationOptions = contentLocalizations?.map((contentLocalization: KeyPairs) => {
        return {
            label: `${contentLocalization.values[1]} (${contentLocalization.values[0]})`,
            value: contentLocalization.values[0],
        }
    })

    const expandRow = (row: ContentAssist) => (
        <ContentAssistDetail key={row.id} isNew={false} wrapper={row}  callback={setChange} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchContentRecommendations(searchOptions)
    }

    /**
     * Fetch relationship list
     * */
    const contentRelationshipQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('contentRelationships', fetchRelationships, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Calculate week range by month and selected development stage
     */
    const calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateWeekRangeByMonthAndSelectedDevelopmentStage)

    const availableRelationshipOptions = contentRelationshipQuery.data?.map((contentRelationship) => {
        return {
            value: contentRelationship.id,
            label: contentRelationship.name,
        }
    })

    const availableDevelopmentStageOptions = developmentStageQuery.data?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    });

    const customSearchOptionsRenderer = () => (
        <>
            <TextField
                variant="filled"
                name="queryVector"
                value={customSearchOptions.queryVector}
                label="Query Vector..."
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        queryVector: event.target.value,
                    })
                }}
                required={true}
            />

            <TextField
                type="number"
                variant="filled"
                name="topk"
                value={customSearchOptions.topk}
                label="Topk..."
                InputProps={{
                    inputProps: {
                        min: 1,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        topk: parseInt(event.target.value),
                    })
                }}
                required={true}
            />

            <AutocompleteExt
                name="developmentStageIds"
                multiSelection={true}
                label="Development Stage..."
                selectedValue={customSearchOptions.developmentStageIds}
                onSelect={(value) => {
                    if (value.length == 1) {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            developmentStageIds: value,
                            developmentStage: developmentStageQuery.data?.find((developmentStage) => {
                                return developmentStage.id === value[0]
                            }),
                            month: NaN,
                            weekFrom: NaN,
                            weekTo: NaN,
                        })
                    } else {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            developmentStageIds: value,
                            developmentStage: undefined,
                            month: NaN,
                            weekFrom: NaN,
                            weekTo: NaN,
                        })
                    }
                }}
                options={availableDevelopmentStageOptions}
            />

            {customSearchOptions.developmentStage?.supportedMonthConversion && (
                <TextField
                    type="number"
                    variant="filled"
                    name="month"
                    value={customSearchOptions.month}
                    label={`Month (${customSearchOptions.developmentStage?.validMonthFrom} - ${customSearchOptions.developmentStage?.validMonthTo})...`}
                    InputProps={{
                        inputProps: {
                            min: customSearchOptions.developmentStage?.validMonthFrom,
                            max: customSearchOptions.developmentStage?.validMonthTo,
                        },
                    }}
                    onBlur={(event) => {
                        if (!customSearchOptions.month) {
                            return;
                        }

                        if (customSearchOptions.month < customSearchOptions.developmentStage!!.validMonthFrom || customSearchOptions.month > customSearchOptions.developmentStage!!.validMonthTo) {
                            return;
                        }

                        calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.mutate({
                            month: customSearchOptions.month,
                            developmentStageId: customSearchOptions.developmentStage?.id,
                        }, {
                            onSuccess: (data) => {
                                setCustomSearchOptions({
                                    ...customSearchOptions,
                                    month: customSearchOptions.month,
                                    weekFrom: data[0],
                                    weekTo: data[1],
                                })
                            }
                        })
                    }}
                    onChange={(event) => {
                        if (!event.target.value) {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                month: NaN,
                                weekFrom: NaN,
                                weekTo: NaN,
                            })
                        } else {
                            const month = parseInt(event.target.value)

                            setCustomSearchOptions({
                                ...customSearchOptions,
                                month: month,
                            })
                        }
                    }}
                />
            )}

            <TextField
                type="number"
                variant="filled"
                name="weekFrom"
                value={customSearchOptions.weekFrom}
                label={customSearchOptions.developmentStage?.id ? `Week (${customSearchOptions.developmentStage?.weekFrom} - ${customSearchOptions.developmentStage?.weekTo})` : 'Week...' }
                InputProps={{
                    inputProps: {
                        min: customSearchOptions.developmentStage?.weekFrom,
                        max: customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold : customSearchOptions.developmentStage?.weekTo,
                    },
                }}
                onChange={(event) => {
                    if (!event.target.value) {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            weekFrom: undefined,
                            weekTo: undefined,
                        })
                    } else {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            weekFrom: parseInt(event.target.value),
                        })
                    }
                }}
                required={customSearchOptions.developmentStageIds.length === 0}
            />

            <TextField
                type="number"
                variant="filled"
                name="weekTo"
                value={customSearchOptions.weekTo}
                label={customSearchOptions.developmentStage?.id ? `Week To (Max ${customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage.maxThreshold : customSearchOptions.developmentStage?.weekTo })` : 'Week To...' }
                InputProps={{
                    inputProps: {
                        min: customSearchOptions.weekFrom ? customSearchOptions.weekFrom : customSearchOptions.developmentStage?.weekFrom,
                        max: customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold : customSearchOptions.developmentStage?.weekTo,
                    },
                }}
                onChange={(event) => {
                    if (!event.target.value) {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            weekTo: undefined,
                        })
                    } else {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            weekTo: parseInt(event.target.value),
                        })
                    }
                }}
                disabled={!customSearchOptions.weekFrom}
            />

            <CheckboxExt
                name="includeScore"
                value={customSearchOptions.includeScore}
                label="Include Score"
                onChange={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        includeScore: v,
                    })
                }}
            />

            <AutocompleteExt
                name="relationshipId"
                multiSelection={false}
                label="Relationship..."
                selectedValue={customSearchOptions.relationshipId}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        relationshipId: value,
                    })
                }}
                options={availableRelationshipOptions}
            />

            <TextField
                type="number"
                variant="filled"
                name="acceptableVectorSearchScore"
                value={customSearchOptions.acceptableVectorSearchScore}
                label="Acceptable Vector Search Score..."
                InputProps={{
                    inputProps: {
                        max: 1.0,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        acceptableVectorSearchScore: parseFloat(event.target.value),
                    })
                }}
                required={customSearchOptions.includeScore}
            />

            <AutocompleteExt
                name="contentLocalizationCodes"
                multiSelection={true}
                label="Content localization..."
                selectedValue={customSearchOptions.contentLocalizationCodes}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        contentLocalizationCodes: value,
                    })
                }}
                options={contentLocalizationOptions}
            />

            <TextField
                variant="filled"
                name="structureRegex"
                value={customSearchOptions.structureRegex}
                label="Structure Regex..."
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        structureRegex: event.target.value,
                    })
                }}
            />

            <TextField
                variant="filled"
                name="structureExclusiveRegex"
                value={customSearchOptions.structureExclusiveRegex}
                label="Structure Exclusive Regex..."
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        structureExclusiveRegex: event.target.value,
                    })
                }}
                disabled={!customSearchOptions.structureRegex}
            />

            <CheckboxExt
                name="milestone"
                value={customSearchOptions.milestone}
                label="Milestone Only..."
                onChange={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        milestone: v,
                    })
                }}
            />

            <AutocompleteExt
                name="translateTo"
                multiSelection={false}
                label="Translate to..."
                selectedValue={customSearchOptions.translateTo}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        translateTo: value,
                    })
                }}
                options={translationOptions}
            />
        </>
    )

    const columns = [
        {
            dataField: 'title',
            text: 'Title',
        },
        {
            dataField: 'contentTypes',
            text: 'Content Type',
            converter: (value: any) => {
                if (!value || !Array.isArray(value) || value.length === 0) {
                    return '';
                }

                const joinTypesRecursive = (item: any): string => {
                    if (!item || !item.type) {
                        return '';
                    }

                    if (item.parent && item.parent.type) {
                        return joinTypesRecursive(item.parent) + ' > ' + item.type;
                    } else {
                        return item.type;
                    }
                };

                let concatenatedTypes = joinTypesRecursive(value[value.length - 1]);

                // Capitalized all first letter
                concatenatedTypes = concatenatedTypes.replace(/\w\S*/g, (txt) => {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                })

                return concatenatedTypes;
            },
        },
        {
            dataField: 'milestone',
            text: 'Milestone',
            converter: (value: any) => {
                if (value) {
                    return 'Yes';
                }
                return 'No';
            },
        },
        {
            dataField: 'locale',
            text: 'Locale',
        },
        {
            dataField: 'week',
            text: 'Week',
        },
        {
            dataField: 'day',
            text: 'Day of Week',
        },
        {
            dataField: 'due',
            text: 'Due',
        },
        {
            dataField: 'weekTo',
            text: 'Week To',
            converter: (value: any) => {
                if (!value) {
                    return '-';
                }
                return value
            }
        },
        {
            dataField: 'dueTo',
            text: 'Due To',
            converter: (value: any) => {
                if (!value) {
                    return '-';
                }
                return value
            }
        },
        {
            dataField: 'priorityOrder',
            text: 'Priority',
            converter: (value: any) => {
                if (value === 1) {
                    return "Main"
                }
                return "Random"
            },
        },
        {
            dataField: 'score',
            text: 'Score',
        },
    ]

    /**
     * Page containing content
     */
    return (
        <Box m="20px">
            <LoadingOverlay active={calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                <Header title="Content Assist Recommendation" />
                <DataGridFilter
                    keyField="id"
                    useQueryKey={`contentAssistRecommendations`}
                    change={change}
                    columns={columns}
                    onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                    searchFilterCols={3}
                    customSearchOptions={customSearchOptions}
                    resetCustomSearchOptions={(v: any) => {
                        setCustomSearchOptions(v)
                    }}
                    customSearchOptionsRenderer={customSearchOptionsRenderer()}
                    disabledKeyword={true}
                    disabledMatchAll={true}
                    expandRow={expandRow}
                    hasPagination={false}
                    preloadEnabled={false}
                />
            </LoadingOverlay>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { localeList: state.localeList.data, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentAssistRecommendations)