import React, { FunctionComponent, useEffect, useState } from 'react';
import MDEditor, {
  commands, TextState,
  ICommand,
  TextAreaTextApi,
  selectWord
} from '@uiw/react-md-editor';
import '../styles/RichTextEditor.css';
import { Button, FormHelperText, useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


interface RichTextAreaEditorProp {
  value: string
  onBlurEvent?: (event: any) => void
  onChangeEvent?: (v: string) => void
  error?: boolean | undefined
  helperText?: React.ReactNode
  editable?: boolean
}


// Custom underline editor command for toolbar
const underline: ICommand = {
  name: "underline",
  keyCommand: "underline",
  buttonProps: { "aria-label": "Add underline text", "title": "Add underline text" },
  icon: (
    <svg width="12" height="12" viewBox="0 0 1024 1024">
      <path
        fill="currentColor"
        d="M960 896V1024H64v-128zM256 0v448a256 256 0 1 0 512 0V0h128v448c0 212.0704-171.9296 384-384 384s-384-171.9296-384-384V0z"
      />
    </svg>
  ),
  execute: (state: TextState, api: TextAreaTextApi) => {
    // Adjust the selection to encompass the whole word if the caret is inside one
    const newSelectionRange = selectWord({
      text: state.text,
      selection: state.selection,
      prefix: ''
    });
    const state1 = api.setSelectionRange(newSelectionRange);
    // Replaces the current selection with the strikethrough mark up
    const state2 = api.replaceSelection(`<u>${state1.selectedText}</u>`);
    // Adjust the selection to not contain the ~~
    api.setSelectionRange({
      start: state2.selection.end - 4 - state1.selectedText?.length,
      end: state2.selection.end - 4
    });
  }
};


const RichTextEditor: FunctionComponent<RichTextAreaEditorProp> = ({
  onBlurEvent,
  onChangeEvent,
  value,
  error,
  helperText,
  editable = true
}) => {
  const [textValue, setTextValue] = React.useState<string>(value || "");
  const [mode, setMode] = useState<string>('markdown')
  const theme = useTheme()

  const onInternalBlurEvent = async (event: any) => {
    if (onBlurEvent) {
      onBlurEvent(event)
    }
  }

  const onInternalChangeEvent = () => {
    if (onChangeEvent) {
      onChangeEvent(textValue)
    }
  }

  useEffect(() => {
    onInternalChangeEvent();
  }, [textValue])

  const editorStyles = `
  .w-md-editor-toolbar {
    background-color: ${theme.palette.mode === 'dark' ? '#1F2A40' : 'white'};
  }

  .w-md-editor-toolbar li > button {
    background-color: ${theme.palette.mode === 'dark' ? '#141b2d' : '#F0F0F0'};
  }

  .wmde-markdown {
    background-color: ${theme.palette.mode === 'dark' ? '#141b2d' : 'white'};
  }

  .w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
    background-color: ${theme.palette.mode === 'dark' ? '#1F2A40' : 'white'}
  }
`;

  useEffect(() => {
    setTextValue(value || "")
  }, [value])

  return (
    <FormHelperText onBlur={onInternalBlurEvent} error={error}>
      <div>
        <MDEditor
          height={350}
          value={textValue}
          onChange={(value, event, state?) => setTextValue(value || "")}
          preview={mode === 'markdown' ? 'edit' : 'preview'}
          style={{ backgroundColor: theme.palette.mode === 'dark' ? '#141b2d' : 'white' }}
          commands={!editable ? [] : [
            commands.group([
              {
                ...commands.title1,
                // @ts-ignore
                icon: { ...commands.title1.icon!!, props: { ...commands.title1.icon!!.props, children: "h1" } }
              },
              {
                ...commands.title2,
                // @ts-ignore
                icon: { ...commands.title2.icon!!, props: { ...commands.title2.icon!!.props, children: "h2" } }
              },
              {
                ...commands.title3,
                // @ts-ignore
                icon: { ...commands.title3.icon!!, props: { ...commands.title3.icon!!.props, children: "h3" } }
              },
              {
                ...commands.title4,
                // @ts-ignore
                icon: { ...commands.title4.icon!!, props: { ...commands.title4.icon!!.props, children: "h4" } }
              },
              {
                ...commands.title5,
                // @ts-ignore
                icon: { ...commands.title5.icon!!, props: { ...commands.title5.icon!!.props, children: "h5" } }
              },
              {
                ...commands.title6,
                // @ts-ignore
                icon: { ...commands.title6.icon!!, props: { ...commands.title6.icon!!.props, children: "h6" } }
              }
            ], {
              name: 'title',
              groupName: 'title',
              buttonProps: { 'aria-label': 'Insert title' },
            }),
            commands.bold,
            commands.italic,
            underline,
            commands.strikethrough,
            commands.orderedListCommand,
            commands.unorderedListCommand,
            commands.link,
            commands.image,
            commands.codeBlock,
            commands.quote,
            commands.table,
          ]}
          data-color-mode={theme.palette.mode === 'dark' ? 'dark' : 'light'}
          textareaProps={{ readOnly: !editable ? true : false }}
          components={{
            toolbar: (command, disabled, executeCommand) => {
              if (command.keyCommand === 'group') {
                return (
                  <CustomButton
                    disabled={disabled}
                    style={{ marginLeft: 1, width: 100 }}
                    executeCommand={executeCommand}
                    command={command}
                    endIcon={<ArrowDropDownIcon fontSize='small' />}
                    buttonTitle='Add a title'
                  />
                )
              }
              if (command.name === 'edit') {
                return (
                  <CustomButton
                    style={{ width: 100 }}
                    mode={"preview"}
                    setMode={setMode}
                    executeCommand={executeCommand}
                    command={command}
                    buttonTitle='Preview mode'
                  />
                )
              }
              if (command.name === 'preview') {
                return (
                  <CustomButton
                    style={{ width: 120 }}
                    mode={"markdown"}
                    setMode={setMode}
                    executeCommand={executeCommand}
                    command={command}
                    buttonTitle='Markdown mode'
                  />
                )
              }
            }
          }}
          extraCommands={
            mode === 'markdown' ? [
              commands.codeEdit,
              commands.fullscreen
            ] : [
              commands.codePreview,
              commands.fullscreen
            ]}
        />
      </div>
      {helperText}
      <style>{editorStyles}</style>
    </FormHelperText>
  )
}

export default RichTextEditor;

interface CustomButtonProps {
  disabled?: boolean,
  style: {},
  buttonTitle: string,
  onClick?: () => void,
  endIcon?: React.ReactNode,
  executeCommand: (command: commands.ICommand<string>, name?: string | undefined) => void,
  command: commands.ICommand<string>,
  mode?: string,
  setMode?: (value: React.SetStateAction<string>) => void
}

const CustomButton = ({
  disabled,
  style,
  buttonTitle,
  endIcon,
  executeCommand,
  command,
  mode,
  setMode
}: CustomButtonProps) => {
  const theme = useTheme();
  return (
    <Button
      disabled={disabled}
      style={{
        ...style,
        backgroundColor: `${theme.palette.mode === 'dark' ? '#141b2d' : '#F0F0F0'}`,
        marginBottom: 4,
        color: !endIcon ? `${theme.palette.mode === 'dark'  ? 'white' : 'black'}` : ''
      }}
      size='large'
      onClick={(evn) => {
        evn.preventDefault();
        evn.stopPropagation();
        executeCommand(command, command.groupName);
        if (setMode && mode) {
          setMode(mode)
        }
      }}
      variant={`${theme.palette.mode === 'dark' ? 'contained' : 'text'}`}
      endIcon={endIcon}
    >
      {buttonTitle}
    </Button>
  )
}