import React, { useState } from 'react'
import { connect } from 'react-redux'
import store from '../redux/store'
import { UPDATE_TAB_INDEX } from '../redux/actionType'
import { Box, Tab, Tabs, useTheme } from '@mui/material'
import { tokens } from '../theme'

const TabsExt = (props: {
    reduxTabsIndexes: any
    name: string
    tabs: TabProp[]
}) => {
    const { reduxTabsIndexes, name, tabs } = props

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    // Retrieve tabs indexes from redux
    const reduxTabsIndex = reduxTabsIndexes.find(
        (eachReduxTabsIndex: any) => eachReduxTabsIndex.key === name
    )
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(
        reduxTabsIndex?.index ? reduxTabsIndex.index : 0
    )
    const handleTabChange = (event: any, indexChange: any) => {
        setCurrentTabIndex(indexChange)
        // Update redux tab index changes
        store.dispatch({
            type: UPDATE_TAB_INDEX,
            payload: { key: name, index: indexChange },
        })
    }

    return (
        <Box
            m="40px 0 0 0"
            sx={{
                '& .Mui-selected': {
                    color: `${colors.blueAccent[100]} !important`,
                    background:
                        theme.palette.mode === 'dark'
                            ? colors.blueAccent[700]
                            : 'none',
                },
            }}
        >
            <Tabs
                value={currentTabIndex}
                onChange={handleTabChange}
                style={{ padding: `10px` }}
            >
                {tabs.map((tab, tabIndex) => {
                    return (
                        <Tab
                            id={tab.name}
                            label={tab.name}
                            defaultChecked={currentTabIndex === tabIndex}
                            sx={{
                                fontWeight: 'bold',
                            }}
                        />
                    )
                })}
            </Tabs>
            {tabs[currentTabIndex].component}
        </Box>
    )
}

interface TabProp {
    name: string
    component: React.ReactElement
}

/**
 * Connect and retrieve the current table data through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { reduxTabsIndexes: state.tabsIndexes.data }
}

export default connect(mapStateToProps)(TabsExt)
