import React from 'react';
import {Box, TextField} from '@mui/material';
import * as yup from "yup";
import {Formik} from "formik";
import {connect} from "react-redux";
import {ContentServerConfig} from "../../../../../interfaces/ServerConfigType";
import AutocompleteExt from "../../../../../components/Autocomplete";
import {referenceItemMapperStrategyDialectOptions} from "../../../../../share/SystemConfigConstant";
import ButtonExt from "../../../../../components/ButtonExt";

const contentServerConfigSchema = yup.object().shape({
    referenceItemConversionMapperDialect: yup.string().required('required'),
    defaultSearchFilterRelationshipId: yup.string().required('required'),
    contentTypeMainPriorityRegex: yup.string().required('required'),
    childRelationshipId: yup.string().required('required'),
    motherRelationshipId: yup.string().required('required'),
    preemieChildRelationshipId: yup.string().required('required'),
    preemieMotherRelationshipId: yup.string().required('required'),
    fatherRelationshipId: yup.string().required('required'),
    prematurePregnancyWeekThreshold: yup.number().required('required'),
    prematureInfancyWeekEndThreshold: yup.number().required('required'),
})

const ContentServerConfigFC: React.FC<ContentServerConfig> = ({
                                                                                          userSetting,
                                                                                          key,
                                                                                          value,
                                                                                          setValue,
                                                                                          onChange,
                                                                                          isLoading,
                                                                                      }) => {

    return (
        <Formik
            onSubmit={onChange}
            initialValues={value}
            validationSchema={contentServerConfigSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(2, minmax(0,1fr))"
                    >
                        <AutocompleteExt
                            name="referenceItemConversionMapperDialect"
                            multiSelection={false}
                            label="Reference Item Conversion Mapper Dialect"
                            selectedValue={
                                values.referenceItemConversionMapperDialect
                            }
                            options={referenceItemMapperStrategyDialectOptions}
                            onBlurEvent={handleBlur}
                            onSelect={(v) => {
                                setValue({
                                    ...values,
                                    referenceItemConversionMapperDialect: v
                                })
                                values.referenceItemConversionMapperDialect = v
                            }}
                            disableUnselectAll={true}
                            required={true}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Default Search Filter Relationship Id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.defaultSearchFilterRelationshipId}
                            name="defaultSearchFilterRelationshipId"
                            error={!!touched.defaultSearchFilterRelationshipId && !!errors.defaultSearchFilterRelationshipId}
                            helperText={touched.defaultSearchFilterRelationshipId && errors.defaultSearchFilterRelationshipId}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Content Type Main Priority Regex"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.contentTypeMainPriorityRegex}
                            name="contentTypeMainPriorityRegex"
                            error={!!touched.contentTypeMainPriorityRegex && !!errors.contentTypeMainPriorityRegex}
                            helperText={touched.contentTypeMainPriorityRegex && errors.contentTypeMainPriorityRegex}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Child Relationship Id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.childRelationshipId}
                            name="childRelationshipId"
                            error={!!touched.childRelationshipId && !!errors.childRelationshipId}
                            helperText={touched.childRelationshipId && errors.childRelationshipId}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Mother Relationship Id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.motherRelationshipId}
                            name="motherRelationshipId"
                            error={!!touched.motherRelationshipId && !!errors.motherRelationshipId}
                            helperText={touched.motherRelationshipId && errors.motherRelationshipId}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Father Relationship Id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.fatherRelationshipId}
                            name="fatherRelationshipId"
                            error={!!touched.fatherRelationshipId && !!errors.fatherRelationshipId}
                            helperText={touched.fatherRelationshipId && errors.fatherRelationshipId}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Preemie Child Relationship Id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.preemieChildRelationshipId}
                            name="preemieChildRelationshipId"
                            error={!!touched.preemieChildRelationshipId && !!errors.preemieChildRelationshipId}
                            helperText={touched.preemieChildRelationshipId && errors.preemieChildRelationshipId}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Preemie Mother Relationship Id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.preemieMotherRelationshipId}
                            name="preemieMotherRelationshipId"
                            error={!!touched.preemieMotherRelationshipId && !!errors.preemieMotherRelationshipId}
                            helperText={touched.preemieMotherRelationshipId && errors.preemieMotherRelationshipId}
                        />

                        <TextField
                            variant="filled"
                            type="number"
                            label="Premature Pregnancy Week Threshold"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.prematurePregnancyWeekThreshold}
                            name="prematurePregnancyWeekThreshold"
                            error={!!touched.prematurePregnancyWeekThreshold && !!errors.prematurePregnancyWeekThreshold}
                            helperText={touched.prematurePregnancyWeekThreshold && errors.prematurePregnancyWeekThreshold}
                        />

                        <TextField
                            variant="filled"
                            type="number"
                            label="Premature Infancy Week End Threshold"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.prematureInfancyWeekEndThreshold}
                            name="prematureInfancyWeekEndThreshold"
                            error={!!touched.prematureInfancyWeekEndThreshold && !!errors.prematureInfancyWeekEndThreshold}
                            helperText={touched.prematureInfancyWeekEndThreshold && errors.prematureInfancyWeekEndThreshold}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        mt="20px"
                        gap="20px"
                    >
                        <ButtonExt
                            type="submit"
                            value={isLoading ? 'Saving' : 'Save'}
                            disabled={
                                isLoading ||
                                !userSetting?.administratorRolePermission?.serverConfigPermission?.editable
                            }
                        />
                    </Box>
                </form>
            )}
        </Formik>
    );
};

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentServerConfigFC)